import React from 'react'
import logo from './logo.svg';
import './App.css';
import CreateSubCollection from './CreateSubCollection.js'
import SubCollection from './SubCollection.js'


class App extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      names: null,
      create: false,
      path: '',
    }
  }

  componentDidMount() {
    const pathName = window.location.pathname
    const path = pathName.split('/')[1]
    const searchParams = new URLSearchParams(window.location.search)
    let update = {
      path
    }

    if (path === 'create') {
      update.create = true
    } else {
      update.create = false
    }

    if (searchParams.has('title')) {
      update.title = searchParams.get('title')
    }

    if (searchParams.has('names')) {
      update.names = searchParams.get('names').split(',')
    }

    this.setState(update)
  }

  createSubCollection = (title, names) => {
    const searchParams = new URLSearchParams()
    searchParams.set('title', title)
    searchParams.set('names', names.join(','))
    window.location = '?' + searchParams.toString()
  }

  resetState = () => {
    this.setState({
      title: null,
      names: null
    })
  }

  render() {
    return (
      <div className="App" style={{ margin: '10px' }}>
        <div className='textbox' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderBottom: '1px solid #ddd'}}>
          <a href="?" style={{ fontWeight: 'bold' }}>
            Avvy Domains Marketplace Aggregator
          </a>
          <div style={{ fontSize: '12px', color: 'white' }}>
            <a href="https://go.avvy.domains/discord">Join the Avvy Domains Discord</a>
            &nbsp;&nbsp;•&nbsp;&nbsp;
            <a href="/" >Create your own SubCollection</a>
          </div>
        </div>
        {this.state.create && this.state.title && this.state.names ? (
          <SubCollection title={this.state.title} names={this.state.names} createCollection={() => this.resetState()} />
        ) : this.state.create ? (
          <CreateSubCollection onCreate={(title, names) => this.createSubCollection(title, names)} />
        ) : this.state.path === '' ? (
          <div style={{ maxWidth: '500px', margin: 'auto', marginTop: '40px' }}>
            <h1>Avvy Domains Marketplace Aggregator</h1>
            <div style={{ marginTop: '40px' }}>
              {'Domain names are not like regular NFTs. lalala123522888s.avax is simply not as nice as 42069.avax, but the two can be listed right beside each other on an NFT exchange. This makes it extremely difficult to compare domains.'}
            </div>
            <div style={{ marginTop: '10px'}}>
              {'We use subcollections and marketplace aggregation to help users explore subsets of the overall .avax namespace.'}
            </div>
            <div style={{ marginTop: '10px'}}>
              {'Try creating your own subcollection below 👇'}
            </div>
            <div style={{ marginTop: '20px' }}>
              <button onClick={() => window.location = '/create'}>Explore a .avax SubCollection</button>
            </div>
          </div>
        ) : (
          <SubCollection path={this.state.path} />
        )}
      </div>
    );
  }
}

export default App;
