/*
import { ethers } from 'ethers'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

 
const abi = [
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "key",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "value",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "ref",
        "type": "string"
      }
    ],
    "name": "create",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "key",
        "type": "string"
      }
    ],
    "name": "exists",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "key",
        "type": "string"
      }
    ],
    "name": "get",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "creator",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "value",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "ref",
            "type": "string"
          }
        ],
        "internalType": "struct SubCollection.Sub",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "name": "subcollections",
    "outputs": [
      {
        "internalType": "address",
        "name": "creator",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "value",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "ref",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "key",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "value",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "ref",
        "type": "string"
      }
    ],
    "name": "update",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]

const address = '0xfd0B1E55A7a2981C0385e814be95171Ab3054850'
const rpcUrl = 'https://api.avax.network/ext/bc/C/rpc'

const mainnet = {
  chainId: 43114,
  name: 'Avalanche',
  currency: 'AVAX',
  explorerUrl: 'https://snowtrace.io',
  rpcUrl: 'https://api.avax.network/ext/bc/C/rpc'
}

const metadata = {
  name: 'Avvy Domains Marketplace Aggregator',
  description: 'explore domains',
  url: 'https://subcollection.avax.sh',
  icons: ['https://avatars.mywebsite.com/']
}

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId: '2ae54a83986090dded7267745420f6b5'
})

const provider = new ethers.JsonRpcProvider(rpcUrl)

class EventEmitter extends EventTarget {

}

const events = new EventEmitter()

modal.subscribeEvents(event => {
  if (event.data?.event === 'CONNECT_SUCCESS') {
    events.dispatchEvent(new Event('connect'))
  }
}) // subscribe to events
*/

const methods = {
  /*
  getSigner: async () => {
    const walletProvider = modal.getWalletProvider()
    const provider = new ethers.BrowserProvider(walletProvider)
    const signer = await provider.getSigner()
    return signer
  },
  getContract: async () => {
    const signer = await methods.getSigner()
    return new ethers.Contract(address, abi, signer)
  },
  connect: () => {
    modal.open()
  },
  isConnected: () => {
    const walletProvider = modal.getWalletProvider()
    return !!walletProvider
  },
  on: (eventName, handler) => {
    events.addEventListener(eventName, handler)
  },
  off: (eventName, handler) => {
    events.removeEventListener(eventName, handler)
  },
  save: async (key, names, affiliateCode) => {
    const payload = names.map(n => n.split('.')[0]).reduce((sum, curr) => {
      if (sum.length > 0) return sum + '|' + curr
      return curr
    }, '')
    const contract = await methods.getContract()
    try {
      const tx = await contract.create(key, payload, affiliateCode)
      const receipt = await tx.wait()
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  },
  load: async (key) => {
    const contract = await methods.getContract()
    const data = await contract.get(key)
    return {
      creator: data.creator, 
      ref: data.ref,
      names: data.value.split('|').map(s => s + '.avax')
    }
  }
  */
  save: async (key, title, names, affiliateCode) => {
    const exists = await methods.exists(key)
    if (exists) {
      alert('Failed to save - slug already exists')
      return false
    }
    alert('We are still working on this. A file is going to be downloaded to your browser, it needs to be uploaded to https://github.com/Avvy-Domains-Hack-Fridays/subcollections/tree/main - Join our Discord and share that file with us to get some more information or help.')
    const storageObj = {
      title,
      names,
      ref: affiliateCode
    }
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj));
    const elem = document.createElement('a')
    document.body.appendChild(elem)
    elem.setAttribute("href",     dataStr     );
    elem.setAttribute("download", `${key}.json`);
    elem.click();
    document.body.removeChild(elem)
    return true
  },
  exists: async (key) => {
    try {
      await methods.load(key)
      return true
    } catch (err) {
      return false
    }
  },
  load: async (key) => {
    const t = parseInt(Date.now())
    const res = await fetch(`https://raw.githubusercontent.com/Avvy-Domains-Hack-Fridays/subcollections/main/${key}.json?t=${t}`, {
      cache: 'no-cache'
    })
    const text = await res.text() 
    return JSON.parse(text)
  }
}

export default methods
