import React from 'react'


class CreateSubCollection extends React.PureComponent {
  createSubCollection = () => {
    this.props.onCreate(this.title.value, this.namesList.value.split('\n'))
  }

  render() {
    return (
      <div style={{ maxWidth: '500px', margin: 'auto', marginTop: '40px' }}>
        <h1>Create a SubCollection</h1>
        <div style={{ marginTop: '40px' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '20px' }}>{'What do you want to title your subcollection?'}</div>
          <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" class='textbox' ref={(ref) => this.title = ref} style={{ width: '100%', padding: '10px', marginTop: '5px', }} type="text" placeholder="Fruit Names" />
        </div>
        <div style={{ marginTop: '40px' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '20px' }}>{'Type the names that are in your subcollection below'}</div>
          <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" class='textbox' ref={(ref) => this.namesList = ref} style={{ width: '100%', padding: '10px', marginTop: '5px', height: '200px' }} placeholder={'apple.avax\nbanana.avax\ncherry.avax'}>
          
          </textarea>
        </div>
        <div>
          <button onClick={() => this.createSubCollection()}>
            Create SubCollection
          </button>
        </div>
      </div>
    )
  }
}

export default CreateSubCollection
